import { APPS } from '../../dummy/config';
import { DataService } from '../../services/data.service';
import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { pushToArray, ConvertService } from 'src/app/services/convert.service';
@Injectable({ providedIn: 'root' })
export class ReturnNDeliveryCoOutstanding {
    @observable data: Array<any> = [];
    @observable process: boolean = false;
    @observable loading: boolean = true;
    @observable empty: boolean = false;
    lastVisible: any = null;
    @observable orderBy: any = null;
    @observable statusKey: any = 1;
    @observable fetching: boolean = false;
    @observable done: boolean = false;
    @observable item: any = null;

    @observable filterStatus: any = null;
    constructor(private ds: DataService) { }

    @action
    fetchOutstanding(key, companyKey) {
        this.process = true;
        this.ds.registerCODocRef(key, companyKey).valueChanges().subscribe(doc => {
            this.item = doc;
            this.process = false;
        })
    }


    @action
    updateOutstandingReturn(item, callback) {
        this.process = true;
        this.ds.registerCODocRef(item?.key, item?.companyKey).update(
            { 
                hasSelectedReturnCO: false,
                keyword: ConvertService.toCapitalize(`${item?.co_number}`),

             }
            ).then(() => {
            callback(true)
            this.process = true;
        }).catch(error => {
            callback(false)
            this.process = true;
        })
    }




    @action
    updateOutstandingDeliveryExportCo(item, callback) {
        this.process = true;
        this.ds.registerCODocRef(item.key, item.companyKey).update(
            {
                 hasSelectedDeliveryCO: false,
                 keyword: ConvertService.toCapitalize(`${item?.co_number}`),
            }
            ).then(() => {
            callback(true)
            this.process = true;
        }).catch(error => {
            callback(false)
            this.process = true;
        })
    }



    //OUTSTANDING
    @action
    async fetchData(routerName: string, url: any, icon: any, menuName: string, uid: string, statusKey: any, companyKey: string, outstandingField: string, isHistory: boolean = true) {
        this.lastVisible = null;
        this.statusKey = statusKey;
        this.done = false;
        this.loading = true;

        this.orderBy = "submit_form_at";
        const docs = await this.ds.returnDeliveryCoOutstandingRef(this.lastVisible, this.orderBy, this.statusKey, companyKey, outstandingField).get().toPromise();
        this.data = pushToArray(docs);

        if (!docs.empty) {
            this.lastVisible = this.data[this.data.length - 1];
        }
        this.empty = this.data.length === 0;
        this.loading = false;
        if (isHistory) {
            this.ds.userprofileRef().doc(uid).collection("histories").doc(routerName).set({
                url: url,
                name: menuName,
                login_date: new Date(),
                page_key: ConvertService.pageKey(),
                key: routerName,
                icon: icon,
            })
        }
    }

    @action
    async fetchMore(outstandingField: any, companyKey: string) {
        if (this.fetching || this.done || this.lastVisible === null || this.loading) { return; }
        this.fetching = true;
        const docsData = await this.ds.returnDeliveryCoOutstandingRef(this.lastVisible, this.orderBy, this.statusKey, companyKey, outstandingField).get().toPromise();
        const docs = pushToArray(docsData);
        if (docs.length > 0 && docs.length >= APPS.size) {
            this.lastVisible = docs[docs.length - 1];
        } else {
            this.done = true;
            this.lastVisible = null;
        }
        this.data = this.data.slice().concat(docs);
        this.fetching = false;
    }

    @action
    async fetchSearch(search: any, outstandingField: string, companyKey: string) {
        return this.ds.returnNDeliveryCoOutstandingRef(this.lastVisible, "submit_form_at", 0, companyKey, outstandingField, search).valueChanges().subscribe(docs => {
            this.data = docs;
            if (docs.length > 0 && docs.length >= APPS.size) {
                this.lastVisible = docs[docs.length - 1];
            } else {
                this.done = true;
                this.lastVisible = null;
            }
        });
    }

    @action
    fetchList(registerField: string, registerFieldKey: string, companyKey: string, callback) {
        this.loading = true;
        this.ds.outstandingReturnNDeliveryCoListRef(registerField, registerFieldKey, companyKey).valueChanges().subscribe(docs => {
            this.loading = false;
            callback(docs)
        })
    }

}
